import { render, staticRenderFns } from "./GameMenuOverlay.vue?vue&type=template&id=2b9e1cec&scoped=true&"
import script from "./GameMenuOverlay.vue?vue&type=script&lang=js&"
export * from "./GameMenuOverlay.vue?vue&type=script&lang=js&"
import style0 from "./GameMenuOverlay.vue?vue&type=style&index=0&id=2b9e1cec&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b9e1cec",
  null
  
)

export default component.exports