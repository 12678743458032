<template>
  <div class="container-fluid" style="min-height: 1000px">
    <slide-banner />
    <div class="row p-1" style="background-color: #1b1a2f">
      <div class="col-xl-2 col-md-3 col-4 p-0 p-sm-1">
        <a href="#allgames">
          <b-button
            variant="outline-secondary"
            block
            pill
            :pressed="inputs.allGame"
            @click="onButtonFilter('ทั้งหมด')"
            ><feather-icon icon="AlignJustifyIcon" /> ทั้งหมด</b-button
          >
        </a>
      </div>
      <div class="col-xl-2 col-md-3 col-4 p-0 p-sm-1">
        <a href="#new">
          <b-button
            variant="outline-secondary"
            block
            pill
            :pressed="inputs.newGame"
            @click="onButtonFilter('ใหม่')"
            ><feather-icon icon="StarIcon" /> ใหม่</b-button
          >
        </a>
      </div>
      <div class="col-xl-2 col-md-3 col-4 p-0 p-sm-1">
        <a href="#hot">
          <b-button
            variant="outline-secondary"
            block
            pill
            :pressed="inputs.newTrend"
            @click="onButtonFilter('ยอดนิยม')"
            ><feather-icon icon="BellIcon" /> ยอดนิยม</b-button
          >
        </a>
      </div>
      <div class="col-xl-2 col-md-3 col-12 px-0 px-sm-1 py-1">
        <game-menu-overlay
          @loadSlot="onEnter"
          @filter="onButtonFilter"
        ></game-menu-overlay>
      </div>
      <div class="col-xl-4 col-md-12 col-12 px-0 px-sm-1 py-lg-1">
        <b-form-input
          class="bg-dark input-border"
          placeholder="ค้นหาเกมที่นี่"
          v-model="key"
        />
      </div>
    </div>
    <div class="row justify-content-center mt-1">
      <div class="col-12 col-sm-11 col-xl-9">
        <div class="row" v-if="datas.length > 0" id="list-games">
          <div
            v-for="(data, index) in datas"
            :key="index"
            class="col-sm-3 col-md-2 col-4 game-wrap"
          >
            <div
              class="
              w-100
              h-100
                d-flex
                justify-content-center
                align-items-center
                game-wrap-img
              "
              @click="onClickLoginGame(data.code, 'slot', data.gameId)"
            >
              <b-img :src="data.imgUrl" class="game rounded" style="width: 180px; height: 200px" />
            </div>
            <!-- <b-card

              tag="article"
              class="mb-2 shadow-item card-item-new "
            >
              <b-card-body class="text-center text-truncate p-0">
                <a :href="'/product/detail/' + data.product.code">
                  <div class="img-product">
                    <b-img
                      v-if="data.totalstock.$numberDecimal <= 0"
                      :src="require('@/assets/images/production/sold-out.png')"
                      class="card-img sold-out"
                      @error="setAltImg"
                    />
                    <b-img
                      class="card-img mb-1"
                      fluid
                      :class="{
                        'product-sold-out': data.totalstock.$numberDecimal <= 0,
                      }"
                      :src="$baseURL+data.product.pic"
                      @error="setAltImg"
                    />
                  </div>
                  <h4 class="font-weight-bolder">
                    {{ data.name }}
                  </h4>
                  <span class="mt-1">{{ data.product.detail }}</span>
                </a>
                <div v-if="data.promotion">
                  <p class="pro-Price font-weight-bolder mt-1">
                    {{ [{price:data.product.price.$numberDecimal,percen:data.promotion.value.$numberDecimal}] | toPercen }} <b-badge variant="danger">
                      {{ `-${data.promotion.value.$numberDecimal} %` }}
                    </b-badge>
                  </p>
                  <p
                    class="sale"
                    style="margin-top: -12px;"
                  >
                    {{ data.product.price.$numberDecimal | toCurrency }}
                  </p>
                </div>
                <div v-else>
                  <p class="pro-Price font-weight-bolder mt-1">
                    {{ data.product.price.$numberDecimal | toCurrency }}
                  </p>
                </div>
                <b-button
                  class="rounded zoom-cart w-auto mb-1"
                  variant="primary"
                  style="padding: 10px;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;"
                  :disabled="data.totalstock.$numberDecimal <= 0"
                  @click="onClickAddShop(data.product.code)"
                >
                  <feather-icon icon="ShoppingCartIcon" /> เพิ่มลงตะกร้า
                </b-button>
              </b-card-body>
            </b-card> -->
          </div>
        </div>
        <div class="row justify-content-center" v-if="datas.length <= 0">
          <h1 class="text-white">ไม่พบข้อมูลเกมส์</h1>
        </div>
        <hr class="col-4" />
        <div class="row justify-content-center m-1">
          <span class="text-white">กำลังแสดง {{ datas.length }} จากทั้งหมด {{ totalItem }} เกมส์</span>
        </div>
        <!-- end row -->
        <div class="row justify-content-center m-2">
          <b-button
            v-if="recordPage < totalItem"
            variant="outline-secondary"
            class="col-6 col-xl-3 col-md-4 btn-loader-show"
            :class="{ show: y > 250 }"
            @click="nextLoaderForButton"
            pill
            rounded
            block
            >โหลดเพิ่มเติม</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "@vue/composition-api";
import { useWindowScroll } from "@vueuse/core";
import {
  BCarousel,
  BCarouselSlide,
  BButton,
  BCard,
  BCardBody,
  BBadge,
  BFormCheckbox,
  BFormInput,
  BImg,
  BSpinner,
} from "bootstrap-vue";
import VueNumeric from "vue-numeric";
import SlideBanner from "../homepage/SlideBanner.vue";
import GameMenuOverlay from "./GameMenuOverlay.vue";
import Ripple from "vue-ripple-directive";
// import InfiniteLoading from 'vue-infinite-loading';

export default defineComponent({
  directives: {
    Ripple,
  },
  name: "product-store",
  components: {
    BCarousel,
    BCarouselSlide,
    BButton,
    BCard,
    BCardBody,
    BBadge,
    BFormCheckbox,
    BFormInput,
    BImg,
    BSpinner,
    VueNumeric,
    SlideBanner,
    GameMenuOverlay,
  },
  watch: {
    '$route.fullPath':  {
        handler (search) {
          this.first = true
          this.onLoader()
        },
        deep: true,
        immediate: true
    },
    key() {
      this.onLoader();
    },
    // "inputs.allGame": {
    //   handler(val) {
    //     this.onLoader();
    //   },
    // },
    // "inputs.newGame": {
    //   handler(val) {
    //     this.onLoader();
    //   },
    // },
    // "inputs.newTrend": {
    //   handler(val) {
    //     this.onLoader();
    //   },
    // },
  },
  setup() {
    const { y } = useWindowScroll();

    return { y };
  },
  computed: {
    sizepagee() {
      return this.$store.getters["app/currentBreakPoint"];
    },
  },
  methods: {
    onEnter(val) {
      this.code = val;
      this.onLoader();
      this.onButtonFilter("");
    },
    onLoader() {
      if (this.first) {
        let hash = window.location.hash.substr(1);
        if (hash === "new") {
          this.onButtonFilter("ใหม่");
        } else if (hash === "allgames") {
          this.onButtonFilter("ทั้งหมด");
        } else if (hash === "hot") {
          this.onButtonFilter("ยอดนิยม");
        } else {
          this.code = window.location.hash.substr(1);
          this.onButtonFilter("");
        }

        if (hash === undefined) {
          this.onButtonFilter("");
        }
      }

      // if (this.code === '' || this.code === undefined) {
      //   this.onButtonFilter('ทั้งหมด')
      // }

      this.recordPage = 0;
      this.$store
        .dispatch("game/searchGame", {
          inputs: this.inputs,
          search: this.key,
          type: this.type,
          record: this.recordPage,
          size: this.size,
          code: this.code,
        })
        .then((res) => {
          if (res.data.success) {
            this.first = false;
            this.totalItem = res.data.totalItem;
            this.datas = res.data.items;
            this.recordPage += res.data.items.length;
          }
        });
    },
    nextLoaderForButton() {
      this.$store.commit("game/SET_LOADING", true);
      this.$store
        .dispatch("game/searchGame", {
          inputs: this.inputs,
          search: this.key,
          type: this.type,
          record: this.recordPage,
          size: this.size,
          code: this.code,
        })
        .then((res) => {
          if (res.data.success) {
            this.recordPage += res.data.items.length;
            this.totalLoad += res.data.items.length;
            res.data.items.forEach((element) => {
              this.datas.push(element);
            });
            // this.datas.push(res.data.items)
            this.$store.commit("game/SET_LOADING", false);
          }
        });
    },
    getNextLoader() {
      // this.loading = true;
      window.onscroll = () => {
      if (this.recordPage < this.totalItem) {

        // console.log(this.$route.path)
        let height =
          document.documentElement.scrollHeight -
          document.documentElement.scrollTop;
        // if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        // height -= 400
        //   console.log(height)
        //   console.log(document.documentElement.clientHeight)
        // }
        let bottomOfWindow = height === document.documentElement.clientHeight;
        if (bottomOfWindow) {
          this.$store.commit("game/SET_LOADING", true);
          this.$store
            .dispatch("game/searchGame", {
              inputs: this.inputs,
              search: this.key,
              type: this.type,
              record: this.recordPage,
              size: this.size,
              code: this.code,
            })
            .then((res) => {
              if (res.data.success) {
                this.recordPage += res.data.items.length;
                res.data.items.forEach((element) => {
                  this.datas.push(element);
                });
                // this.datas.push(res.data.items)
                // this.loading = false;
                this.$store.commit("game/SET_LOADING", false);
              }
            });
          }
        }
      };
    },
    onButtonFilter(val) {
      if (val === "ทั้งหมด") {
        this.code = "";
        this.inputs.allGame = true;
        this.inputs.newGame = false;
        this.inputs.newTrend = false;
      } else if (val === "ใหม่") {
        this.code = "";
        this.inputs.allGame = false;
        this.inputs.newGame = true;
        this.inputs.newTrend = false;
      } else if (val === "ยอดนิยม") {
        this.code = "";
        this.inputs.allGame = false;
        this.inputs.newGame = false;
        this.inputs.newTrend = true;
      } else {
        this.inputs.allGame = false;
        this.inputs.newGame = false;
        this.inputs.newTrend = false;
      }
    },
    onClickLoginGame(val, model, gameid) {
      const token = localStorage.getItem("auth_token");
      const auth = this.$store.state.auth.auth;
      if (token !== "" && token !== undefined && auth) {
        this.$store.commit("game/SET_LOADING", true);
        this.$store
          .dispatch("game/loginGame", { type: val, model, gameId: gameid })
          .then((result) => {
            if (result.data.success) {
              // window.open(result.data.items[0].url);
              // window.location.assign(result.data.items[0].url);
              this.$router.push({
                name: "login-game",
                params: {
                  url: result.data.items[0].url,
                },
              });
              this.$store.commit("game/SET_LOADING", false);
            } else {
              this.$store.commit("game/SET_LOADING", false);
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  icon: "AlertCircleIcon",
                  variant: "danger",
                  text: "ล็อกอินเกมส์ไม่สำเร็จ",
                },
              });
            }
          })
          .catch((error) => {
            this.$store.commit("game/SET_LOADING", false);
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                icon: "AlertCircleIcon",
                variant: "danger",
                text: "ล็อกอินเกมส์ไม่สำเร็จ",
              },
            });
            this.isLoading = false;
          });
      } else {
        this.$store.commit("customer/SET_OPEN_REGISTER");
        this.$store.commit("customer/SET_TYPE_MODAL", "login");
      }
    },
  },
  mounted() {
    // if (!this.loading) {
      this.getNextLoader();
    // }
  },
  data() {
    return {
      datas: [],
      recordPage: 0,
      key: "",
      size: 12,
      type: this.$route.params.type === "" ? "" : this.$route.params.type,
      code: "",
      loading: false,
      first: true,

      inputs: {
        allGame: true,
        newGame: false,
        newTrend: false,
      },
      totalItem: 0,
      totalLoad: 12,
    };
  },
});
</script>
<style scoped>
.shadow-item {
  box-shadow: 1px -1px 5px -1px rgba(108, 108, 108, 0.6) !important;
}
.card-img,
.card-img-top,
.card-img-bottom {
  width: 190px;
  margin: auto;
}

.col-filter {
  padding-right: 1rem;
  padding-left: 1rem;
}
@media only screen and (min-width: 1200px) {
  .col-filter {
    flex: 0 0 10.6666666667%;
    max-width: 10.6666666667%;
  }
}
@media (min-width: 576px) and (max-width: 1200px) {
  .col-filter {
    flex: 0 0 15.3333333333%;
    max-width: 15.3333333333%;
  }
}
@media (max-width: 576px) {
  .col-filter {
    flex: 0 0 28.3333333333%;
    max-width: 28.3333333333%;
  }
}

.input-border {
  border-color: gray;
}

.game {
  cursor: pointer;
}

.game:hover {
  transform: translate(3px, 3px);
}

.game-wrap {
  padding: 5px;
  margin-top: 10px;
}
.game-wrap-img img {
  max-width: 100%;
}
@media (max-width: 375px) {
  .game-wrap-img img {
    max-width: 100%;
    max-height: 150px;
  }
}
</style>

<style lang="scss" scoped>
.btn-loader-show {
  opacity: 0;
  transition: all 0.5s ease;

  &.show {
    opacity: 1;
  }
}
</style>
