<template>
  <div>
    <div
      id="menu-game"
      class="overlay"
      @click="closeNav"
      :style="{
        width: isNavOpen ? '100%' : '0',
      }"
    >
      <!-- Overlay content -->
      <div
        class="provider-game"
        :style="{ 'margin-right': isNavOpen ? '0' : '-85vw' }"
      >
        <h2 class="provider-game-title m-0 text-center">
          หมวดหมู่เกมส์
          <span class="provider-content-button close-svg" @click="closeNav">
            <svg
              viewBox="0 0 70.24 70.24"
              class="provider-content-button-svg close-svg close-drawer"
            >
              <path
                class="close-svg close-drawer"
                d="M39.53 35.12L53 21.17a3.18 3.18 0 0 0-.09-4.49 3.18 3.18 0 0 0-4.49.09l-13.3 13.78-13.26-13.78a3.18 3.18 0 0 0-4.49-.09 3.18 3.18 0 0 0-.08 4.49l13.43 13.95-13.43 14a3.17 3.17 0 1 0 4.57 4.4L35.12 39.7l13.26 13.77a3.18 3.18 0 0 0 4.49.09 3.18 3.18 0 0 0 .13-4.49z"
              ></path>
            </svg>
          </span>
        </h2>
        <ul class="game-providers arcade-drawer col-12 mb-10">
          <!-- <li
            class="
              game-providers-list
              arcade-drawer
              pt-10
              game-providers-tab
              close-drawer
            "
            data-category-provider="1"
          >
            <a
              href="#all-games"
              class="
                category-provider-menu category-all-games
                active
                close-drawer
              "
              data-category-filter-id="all-games"
              data-category-filter-name="ทั้งหมด"
              style="height: 75px"
              @click="onButton('all-games')"
            >
              <img
                src="https://m.dafabet.com/th/mobile-arcade/2019-10/game-categories_all_1.png"
                alt="ทั้งหมด"
                class="close-drawer"
              />
              <p class="close-drawer">ทั้งหมด</p>
            </a>
          </li>
          <li
            class="
              game-providers-list
              arcade-drawer
              pt-10
              game-providers-tab
              close-drawer
            "
            data-category-provider="0"
          >
            <a
              href="#new"
              class="category-provider-menu category-new close-drawer"
              data-category-filter-id="new"
              data-category-filter-name="เกมส์ใหม่ล่าสุด!"
              style="height: 75px"
              @click="Filter('ทั้งหมด')"
            >
              <img
                src="https://m.dafabet.com/th/mobile-arcade/2021-10/new-40x40.png"
                alt="เกมส์ใหม่ล่าสุด!"
                class="close-drawer"
              />
              <p class="close-drawer">เกมส์ใหม่ล่าสุด!</p>
            </a>
          </li> -->
          <!-- <li
            class="
              game-providers-list
              arcade-drawer
              pt-10
              game-providers-tab
              close-drawer
            "
            data-category-provider="0"
          >
            <a
              href="#promo"
              class="category-provider-menu category-promo close-drawer"
              data-category-filter-id="promo"
              data-category-filter-name="โปรโมชั่น"
              style="height: 75px"
              @click="onButton('promo')"
            >
              <img
                src="https://m.dafabet.com/th/mobile-arcade/2019-11/game-categories_promtions.png"
                alt="โปรโมชั่น"
                class="close-drawer"
              />
              <p class="close-drawer">โปรโมชั่น</p>
            </a>
          </li> -->
          <li
            class="
              game-providers-list
              arcade-drawer
              pt-10
              game-providers-tab
              close-drawer
            "
            data-category-provider="1"
          >
            <a
              href="#pg"
              class="category-provider-menu category-pgsoft close-drawer"
              data-category-filter-id="pgsoft"
              data-category-filter-name="PG Soft"
              style="height: 75px"
            >
              <img
                src="https://m.dafabet.com/th/mobile-arcade/2019-10/pglogo-w-40x40.png"
                alt="PG Soft"
                class="close-drawer"
              />
              <p class="close-drawer">PG Slot</p>
            </a>
          </li>
          <li
            class="
              game-providers-list
              arcade-drawer
              pt-10
              game-providers-tab
              close-drawer
            "
            data-category-provider="1"
          >
            <a
              href="#microgame"
              class="category-provider-menu category-mg close-drawer"
              data-category-filter-id="mg"
              data-category-filter-name="Microgaming"
              style="height: 75px"
            >
              <img
                src="https://m.dafabet.com/th/mobile-arcade/2019-10/microgaming_1.png"
                alt="Microgaming"
                class="close-drawer"
              />
              <p class="close-drawer">Microgaming</p>
            </a>
          </li>
          <!-- <li
            class="
              game-providers-list
              arcade-drawer
              pt-10
              game-providers-tab
              close-drawer
            "
            data-category-provider="1"
          >
            <a
              href="#nextspin"
              class="category-provider-menu category-nextspin close-drawer"
              data-category-filter-id="nextspin"
              data-category-filter-name="Nextspin"
              style="height: 75px"
              @click="onButton('nextspin')"
            >
              <img
                src="https://m.dafabet.com/th/mobile-arcade/2022-01/40x40-nxspin_1.png"
                alt="Nextspin"
                class="close-drawer"
              />
              <p class="close-drawer">Nextspin</p>
            </a>
          </li> -->
          <!-- <li
            class="
              game-providers-list
              arcade-drawer
              pt-10
              game-providers-tab
              close-drawer
            "
            data-category-provider="1"
          >
            <a
              href="#joker-gaming"
              class="category-provider-menu category-joker-gaming close-drawer"
              data-category-filter-id="joker-gaming"
              data-category-filter-name="Joker Gaming"
              style="height: 75px"
              @click="onButton('joker-gaming')"
            >
              <img
                src="https://m.dafabet.com/th/mobile-arcade/2021-12/joker40x40.png"
                alt="Joker Gaming"
                class="close-drawer"
              />
              <p class="close-drawer">Joker Gaming</p>
            </a>
          </li> -->
          <!-- <li
            class="
              game-providers-list
              arcade-drawer
              pt-10
              game-providers-tab
              close-drawer
            "
            data-category-provider="1"
          >
            <a
              href="#ruby-play"
              class="category-provider-menu category-ruby-play close-drawer"
              data-category-filter-id="ruby-play"
              data-category-filter-name="Ruby Play"
              style="height: 75px"
              @click="onButton('ruby-play')"
            >
              <img
                src="https://m.dafabet.com/th/mobile-arcade/2021-06/rubyplay40x40.png"
                alt="Ruby Play"
                class="close-drawer"
              />
              <p class="close-drawer">Ruby Play</p>
            </a>
          </li> -->
          <!-- <li
            class="
              game-providers-list
              arcade-drawer
              pt-10
              game-providers-tab
              close-drawer
            "
            data-category-provider="1"
          >
            <a
              href="#kingmaker"
              class="category-provider-menu category-kingmaker close-drawer"
              data-category-filter-id="kingmaker"
              data-category-filter-name="Kingmaker"
              style="height: 75px"
              @click="onButton('kingmaker')"
            >
              <img
                src="https://m.dafabet.com/th/mobile-arcade/2021-10/kingmaker-icon.png"
                alt="Kingmaker"
                class="close-drawer"
              />
              <p class="close-drawer">Kingmaker</p>
            </a>
          </li> -->
          <!-- <li
            class="
              game-providers-list
              arcade-drawer
              pt-10
              game-providers-tab
              close-drawer
            "
            data-category-provider="0"
          >
            <a
              href="#megaways"
              class="category-provider-menu category-megaways close-drawer"
              data-category-filter-id="megaways"
              data-category-filter-name="MEGAWAYS"
              style="height: 75px"
              @click="onButton('megaways')"
            >
              <img
                src="https://m.dafabet.com/th/mobile-arcade/2019-11/MEGAWAYS40X40FINAL_1.png"
                alt="MEGAWAYS"
                class="close-drawer"
              />
              <p class="close-drawer">MEGAWAYS</p>
            </a>
          </li> -->
          <li
            class="
              game-providers-list
              arcade-drawer
              pt-10
              game-providers-tab
              close-drawer
            "
            data-category-provider="1"
          >
            <a
              href="#allwayspin"
              class="category-provider-menu category-all-ways close-drawer"
              data-category-filter-id="all-ways"
              data-category-filter-name="All-Ways"
              style="height: 75px"
            >
              <img
                src="https://cdn.ambbet.com/logo_doc/allwayspin.png"
                alt="All-Ways"
                class="close-drawer"
              />
              <p class="close-drawer">AllWaySpin</p>
            </a>
          </li>
          <li
            class="
              game-providers-list
              arcade-drawer
              pt-10
              game-providers-tab
              close-drawer
            "
            data-category-provider="0"
          >
            <a
              href="#fishing"
              class="category-provider-menu category-fishing close-drawer"
              data-category-filter-id="fishing"
              data-category-filter-name="ยิงปลา"
              style="height: 75px"
            >
              <img
                src="https://m.dafabet.com/th/mobile-arcade/2019-10/game-categories_fishing_1.png"
                alt="ยิงปลา"
                class="close-drawer"
              />
              <p class="close-drawer">ยิงปลา</p>
            </a>
          </li>
          <!-- <li
            class="
              game-providers-list
              arcade-drawer
              pt-10
              game-providers-tab
              close-drawer
            "
            data-category-provider="0"
          >
            <a
              href="#collapsing"
              class="category-provider-menu category-collapsing close-drawer"
              data-category-filter-id="collapsing"
              data-category-filter-name="ยอดนิยม"
              style="height: 75px"
              @click="onButton('#collapsing')"
            >
              <img
                src="https://m.dafabet.com/th/mobile-arcade/2019-10/game-categories_collapsing_1.png"
                alt="ยอดฮิต"
                class="close-drawer"
              />
              <p class="close-drawer">ยอดนิยม</p>
            </a>
          </li> -->
          <!-- <li
            class="
              game-providers-list
              arcade-drawer
              pt-10
              game-providers-tab
              close-drawer
            "
            data-category-provider="0"
          >
            <a
              href="#crypto"
              class="category-provider-menu category-crypto close-drawer"
              data-category-filter-id="crypto"
              data-category-filter-name="คริปโต"
              style="height: 75px"
              @click="onButton('crypto')"
            >
              <img
                src="https://m.dafabet.com/th/mobile-arcade/2021-02/crypto-icon-40x40_1.png"
                alt="คริปโต"
                class="close-drawer"
              />
              <p class="close-drawer">คริปโต</p>
            </a>
          </li> -->
          <li
            class="
              game-providers-list
              arcade-drawer
              pt-10
              game-providers-tab
              close-drawer
            "
            data-category-provider="0"
          >
            <a
              href="#fixed-odds"
              class="category-provider-menu category-fixed-odds close-drawer"
              data-category-filter-id="fixed-odds"
              data-category-filter-name="วัดดวง"
              style="height: 75px"
              @click="onButton('fixed-odds')"
            >
              <img
                src="https://m.dafabet.com/th/mobile-arcade/2019-10/game-categories_fixed-odds_1.png"
                alt="วัดดวง"
                class="close-drawer"
              />
              <p class="close-drawer">วัดดวง</p>
            </a>
          </li>
          <li
            class="
              game-providers-list
              arcade-drawer
              pt-10
              game-providers-tab
              close-drawer
            "
            data-category-provider="1"
          >
            <a
              href="#wazdan"
              class="category-provider-menu category-wazdan close-drawer"
              data-category-filter-id="wazdan"
              data-category-filter-name="Wazdan"
              style="height: 75px"
            >
              <img
                src="https://m.dafabet.com/th/mobile-arcade/2021-12/Wazdan_40x40-c.png"
                alt="Wazdan"
                class="close-drawer"
              />
              <p class="close-drawer">Wazdan</p>
            </a>
          </li>
          <!-- <li
            class="
              game-providers-list
              arcade-drawer
              pt-10
              game-providers-tab
              close-drawer
            "
            data-category-provider="1"
          >
            <a
              href="#advantplay"
              class="category-provider-menu category-advantplay close-drawer"
              data-category-filter-id="advantplay"
              data-category-filter-name="Advantplay"
              style="height: 75px"
              @click="onButton('advantplay')"
            >
              <img
                src="https://m.dafabet.com/th/mobile-arcade/2021-10/advantplay-icon.png"
                alt="Advantplay"
                class="close-drawer"
              />
              <p class="close-drawer">Advantplay</p>
            </a>
          </li> -->
          <!-- <li
            class="
              game-providers-list
              arcade-drawer
              pt-10
              game-providers-tab
              close-drawer
            "
            data-category-provider="1"
          >
            <a
              href="#elk"
              class="category-provider-menu category-elk close-drawer"
              data-category-filter-id="elk"
              data-category-filter-name="Elk"
              style="height: 75px"
              @click="onButton('elk')"
            >
              <img
                src="https://m.dafabet.com/th/mobile-arcade/2021-09/Elk.png"
                alt="Elk"
                class="close-drawer"
              />
              <p class="close-drawer">Elk</p>
            </a>
          </li> -->
          <!-- <li
            class="
              game-providers-list
              arcade-drawer
              pt-10
              game-providers-tab
              close-drawer
            "
            data-category-provider="1"
          >
            <a
              href="#maverick"
              class="category-provider-menu category-maverick close-drawer"
              data-category-filter-id="maverick"
              data-category-filter-name="Maverick"
              style="height: 75px"
              @click="onButton('maverick')"
            >
              <img
                src="https://m.dafabet.com/th/mobile-arcade/2021-09/40x40mv.png"
                alt="Maverick"
                class="close-drawer"
              />
              <p class="close-drawer">Maverick</p>
            </a>
          </li> -->
          <!-- <li
            class="
              game-providers-list
              arcade-drawer
              pt-10
              game-providers-tab
              close-drawer
            "
            data-category-provider="1"
          >
            <a
              href="#blueprint"
              class="category-provider-menu category-blueprint close-drawer"
              data-category-filter-id="blueprint"
              data-category-filter-name="Blueprint"
              style="height: 75px"
              @click="onButton('blueprint')"
            >
              <img
                src="https://m.dafabet.com/th/mobile-arcade/2020-05/blurprint-40x40.png"
                alt="Blueprint"
                class="close-drawer"
              />
              <p class="close-drawer">Blueprint</p>
            </a>
          </li> -->
          <li
            class="
              game-providers-list
              arcade-drawer
              pt-10
              game-providers-tab
              close-drawer
            "
            data-category-provider="1"
          >
            <a
              href="#kagaming"
              class="category-provider-menu category-ka-gaming close-drawer"
              data-category-filter-id="ka-gaming"
              data-category-filter-name="KA Gaming"
              style="height: 75px"
            >
              <img
                src="https://m.dafabet.com/th/mobile-arcade/2020-10/ka_v1_40x40.png"
                alt="KA Gaming"
                class="close-drawer"
              />
              <p class="close-drawer">KA Gaming</p>
            </a>
          </li>
          <li
            class="
              game-providers-list
              arcade-drawer
              pt-10
              game-providers-tab
              close-drawer
            "
            data-category-provider="1"
          >
            <a
              href="#pragmaticslot"
              class="
                category-provider-menu category-pragmatic-play
                close-drawer
              "
              data-category-filter-id="pragmatic-play"
              data-category-filter-name="Pragmatic Play"
              style="height: 75px"
            >
              <img
                src="https://m.dafabet.com/th/mobile-arcade/2020-10/pragmaticplay-40x40.png"
                alt="Pragmatic Play"
                class="close-drawer"
              />
              <p class="close-drawer">Pragmatic Play</p>
            </a>
          </li>
          <li
            class="
              game-providers-list
              arcade-drawer
              pt-10
              game-providers-tab
              close-drawer
            "
            data-category-provider="1"
          >
            <a
              href="#live22"
              class="category-provider-menu category-skywind close-drawer"
              data-category-filter-id="live22"
              data-category-filter-name="Live22"
              style="height: 75px"
            >
              <img
                src="https://cdn.ambbet.com/logo_doc/live22.png"
                alt="Skywind"
                class="close-drawer"
              />
              <p class="close-drawer">Live22</p>
            </a>
          </li>
          <li
            class="
              game-providers-list
              arcade-drawer
              pt-10
              game-providers-tab
              close-drawer
            "
            data-category-provider="1"
          >
            <a
              href="#ameba"
              class="category-provider-menu category-play-n-go close-drawer"
              data-category-filter-id="ameba"
              data-category-filter-name="ameba"
              style="height: 75px"
            >
              <img
                src="https://cdn.ambbet.com/logo_doc/ameba.png"
                alt="Ameba"
                class="close-drawer"
              />
              <p class="close-drawer">Ameba</p>
            </a>
          </li>
          <li
            class="
              game-providers-list
              arcade-drawer
              pt-10
              game-providers-tab
              close-drawer
            "
            data-category-provider="1"
          >
            <a
              href="#simpleplay"
              class="category-provider-menu category-simpleplay close-drawer"
              data-category-filter-id="simpleplay"
              data-category-filter-name="SimplePlay"
              style="height: 75px"
            >
              <img
                src="https://m.dafabet.com/th/mobile-arcade/2020-09/40x40v1.png"
                alt="SimplePlay"
                class="close-drawer"
              />
              <p class="close-drawer">SimplePlay</p>
            </a>
          </li>
          <li
            class="
              game-providers-list
              arcade-drawer
              pt-10
              game-providers-tab
              close-drawer
            "
            data-category-provider="1"
          >
            <a
              href="#spg"
              class="category-provider-menu category-cq9 close-drawer"
              data-category-filter-id="spade-gaming"
              data-category-filter-name="SpadeGaming"
              style="height: 75px"
            >
              <img
                src="https://cdn.ambbet.com/logo_doc/spg.png"
                alt="SpadeGaming"
                class="close-drawer"
              />
              <p class="close-drawer">Spadegaming</p>
            </a>
          </li>
          <li
            class="
              game-providers-list
              arcade-drawer
              pt-10
              game-providers-tab
              close-drawer
            "
            data-category-provider="1"
          >
            <a
              href="#ganapati"
              class="category-provider-menu category-jdb close-drawer"
              data-category-filter-id="gamatron"
              data-category-filter-name="Gamatron"
              style="height: 75px"
            >
              <img
                src="https://cdn.ambbet.com/logo_doc/ganapati.png"
                alt="Gamatron"
                class="close-drawer"
              />
              <p class="close-drawer">Gamatron</p>
            </a>
          </li>
          <li
            class="
              game-providers-list
              arcade-drawer
              pt-10
              game-providers-tab
              close-drawer
            "
            data-category-provider="1"
          >
            <a
              href="#slotxo"
              class="category-provider-menu category-red-tiger close-drawer"
              data-category-filter-id="SlotXo"
              data-category-filter-name="SlotXo"
              style="height: 75px"
            >
              <img
                src="https://cdn.ambbet.com/logo_doc/slotxo.png"
                alt="Slot Xo"
                class="close-drawer"
              />
              <p class="close-drawer">SlotXo</p>
            </a>
          </li>
          <li
            class="
              game-providers-list
              arcade-drawer
              pt-10
              game-providers-tab
              close-drawer
            "
            data-category-provider="0"
          >
            <a
              href="#askmebetslot"
              class="category-provider-menu category-slotfactory close-drawer"
              data-category-filter-id="askmebetslot"
              data-category-filter-name="Askmebetslot"
              style="height: 75px"
            >
              <img
                src="https://cdn.ambbet.com/logo_doc/amb.png"
                alt="Askmebetslot"
                class="close-drawer"
              />
              <p class="close-drawer">Askmebet</p>
            </a>
          </li>
          <li
            class="
              game-providers-list
              arcade-drawer
              pt-10
              game-providers-tab
              close-drawer
            "
            data-category-provider="1"
          >
            <a
              href="#booongo"
              class="category-provider-menu category-1x2 close-drawer"
              data-category-filter-id="booongo"
              data-category-filter-name="Booongo"
              style="height: 75px"
            >
              <img
                src="https://cdn.ambbet.com/logo_doc/booongo.png"
                alt="Booongo"
                class="close-drawer"
              />
              <p class="close-drawer">Booongo</p>
            </a>
          </li>
          <li
            class="
              game-providers-list
              arcade-drawer
              pt-10
              game-providers-tab
              close-drawer
            "
            data-category-provider="1"
          >
            <a
              href="#iconicgaming"
              class="category-provider-menu category-betsoft close-drawer"
              data-category-filter-id="iconicgaming"
              data-category-filter-name="IconicGaming"
              style="height: 75px"
            >
              <img
                src="https://cdn.ambbet.com/logo_doc/iconicgaming.png"
                alt="Betsoft"
                class="close-drawer"
              />
              <p class="close-drawer">Iconic</p>
            </a>
          </li>
          <li
            class="
              game-providers-list
              arcade-drawer
              pt-10
              game-providers-tab
              close-drawer
            "
            data-category-provider="1"
          >
            <a
              href="#evoplay"
              class="category-provider-menu category-evoplay close-drawer"
              data-category-filter-id="evoplay"
              data-category-filter-name="EVO"
              style="height: 75px"
            >
              <img
                src="https://m.dafabet.com/th/mobile-arcade/2019-10/evoplay_1.png"
                alt="EVO"
                class="close-drawer"
              />
              <p class="close-drawer">EVO</p>
            </a>
          </li>
          <li
            class="
              game-providers-list
              arcade-drawer
              pt-10
              game-providers-tab
              close-drawer
            "
            data-category-provider="1"
          >
            <a
              href="#funtagaming"
              class="category-provider-menu category-habanero close-drawer"
              data-category-filter-id="funtagaming"
              data-category-filter-name="Funtagaming"
              style="height: 75px"
            >
              <img
                src="https://cdn.ambbet.com/logo_doc/funtagaming.png"
                alt="Funtagaming"
                class="close-drawer"
              />
              <p class="close-drawer">Funta</p>
            </a>
          </li>
          <li
            class="
              game-providers-list
              arcade-drawer
              pt-10
              game-providers-tab
              close-drawer
            "
            data-category-provider="1"
          >
            <a
              href="#funkygame"
              class="category-provider-menu category-woohoo close-drawer"
              data-category-filter-id="funkygame"
              data-category-filter-name="Funkygame"
              style="height: 75px"
            >
              <img
                src="https://cdn.ambbet.com/logo_doc/funkygame.png"
                alt="Funkygame"
                class="close-drawer"
              />
              <p class="close-drawer">Funkygame</p>
            </a>
          </li>
          <li
            class="
              game-providers-list
              arcade-drawer
              pt-10
              game-providers-tab
              close-drawer
            "
            data-category-provider="1"
          >
            <a
              href="#mannaplay"
              class="category-provider-menu category-kalamba close-drawer"
              data-category-filter-id="mannaplay"
              data-category-filter-name="Mannaplay"
              style="height: 75px"
            >
              <img
                src="https://cdn.ambbet.com/logo_doc/mannaplay.png"
                alt="Mannaplay"
                class="close-drawer"
              />
              <p class="close-drawer">Mannaplay</p>
            </a>
          </li>
          <li
            class="
              game-providers-list
              arcade-drawer
              pt-10
              game-providers-tab
              close-drawer
            "
            data-category-provider="0"
          >
            <a
              href="#ambslot"
              class="category-provider-menu category-sweets-gems close-drawer"
              data-category-filter-id="ambslot"
              data-category-filter-name="Ambslot"
              style="height: 75px"
            >
              <img
                src="https://cdn.ambbet.com/logo_doc/ambslot.png"
                alt="ambslot"
                class="close-drawer"
              />
              <p class="close-drawer">Ambslot</p>
            </a>
          </li>
          <li
            class="
              game-providers-list
              arcade-drawer
              pt-10
              game-providers-tab
              close-drawer
            "
            data-category-provider="1"
          >
            <a
              href="#jili"
              class="category-provider-menu category-others close-drawer"
              data-category-filter-id="jili"
              data-category-filter-name="Jili"
              style="height: 75px"
            >
              <img
                src="https://cdn.ambbet.com/logo_doc/jili.png"
                alt="Jili"
                class="close-drawer"
              />
              <p class="close-drawer">Jili</p>
            </a>
          </li>
          <li
            class="
              game-providers-list
              arcade-drawer
              pt-10
              game-providers-tab
              close-drawer
            "
            data-category-provider="1"
          >
            <a
              href="#yggdrasil"
              class="category-provider-menu category-others close-drawer"
              data-category-filter-id="yggdrasil"
              data-category-filter-name="Yggdrasil"
              style="height: 75px"
            >
              <img
                :src="require('@/assets/images/production/slot/yggdrasil.png')"
                alt="yggdrasil"
                class="close-drawer"
              />
              <p class="close-drawer">Yggdrasil</p>
            </a>
          </li>
          <li
            class="
              game-providers-list
              arcade-drawer
              pt-10
              game-providers-tab
              close-drawer
            "
            data-category-provider="1"
          >
            <a
              href="#others"
              class="category-provider-menu category-others close-drawer"
              data-category-filter-id="others"
              data-category-filter-name="อื่นๆ"
              style="height: 75px"
            >
              <img
                src="https://m.dafabet.com/th/mobile-arcade/2019-10/game-categories_others_1.png"
                alt="อื่นๆ"
                class="close-drawer"
              />
              <p class="close-drawer">อื่นๆ</p>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <b-button
      variant="outline-secondary"
      block
      pill
      @click="openNav"
      ><feather-icon icon="GitlabIcon" /> ค่ายเกมสล็อต</b-button
    >
  </div>
</template>
<script>
import { defineComponent } from "@vue/composition-api";
import {
  BButton,
} from "bootstrap-vue";

export default defineComponent({
  setup() {},
  name: "GameMenuOverlay",
  components: {
    BButton
  },
  data() {
    return {
      isNavOpen: false,
    };
  },
  methods: {
    openNav() {
      this.isNavOpen = true;
    },
    closeNav() {
      this.isNavOpen = false;
    },
  },
});
</script>


<style scoped>
/* The Overlay (background) */
.overlay {
  /* Height & width depends on how you want to reveal the overlay (see JS below) */
  height: 100%;
  width: 0;
  position: fixed; /* Stay in place */
  z-index: 10000; /* Sit on top */
  left: 0;
  top: 0;
  background-color: rgb(0, 0, 0); /* Black fallback color */
  background-color: rgba(0, 0, 0, 0.9); /* Black w/opacity */
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}

.provider-game {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1010;
  width: 85vw;
  max-width: 500px;
  height: 100%;
  overflow-y: auto;
  transition: margin-right 0.4s ease-in-out;
  background: #373737;
}

.provider-game-title {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 13;
  display: block;
  color: #fff;
  font-size: 1.3rem;
  text-decoration: none;
  font-weight: 600;
  background: #af0000;
  padding: 2rem 3.5rem;
}

.provider-content-button {
  position: absolute;
  width: 4rem;
  height: 6rem;
  top: 0;
  right: 0;
  outline: none;
  border: 0;
  cursor: pointer;
}

.provider-content-button-svg {
  width: 2.5rem;
  height: 3.5rem;
  margin-top: 1rem;
  outline: none;
  border: 0;
  cursor: pointer;
}

.game-providers {
  margin-left: -0.1rem;
  width: 101%;
}

.game-providers {
  float: left;
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: inline-block;
  width: 100%;
  overflow: hidden;
}

.arcade-drawer.game-providers-list.arcade-drawer {
  width: 33.33333%;
  position: relative;
  min-height: 0.1rem;
  float: left;
  border-left: 0.1rem solid #5e5e5e;
}

.arcade-drawer.game-providers a img {
  height: 3.3rem;
  width: 3.3rem;
}

.arcade-drawer.game-providers a p {
  margin: 0;
  padding-top: 0.5rem;
}

.arcade-drawer.game-providers a {
  background: #373737;
}

.game-providers-list {
  border-top: 0.1rem solid #5e5e5e;
  border-top-width: 0.1rem;
  border-top-style: solid;
  border-top-color: rgb(94, 94, 94);
}
.game-providers-list:nth-last-child(2),
.game-providers-list:nth-last-child(3) {
  border-bottom: 0.1rem solid #5e5e5e;
  border-bottom-width: 0.1rem;
  border-bottom-style: solid;
  border-bottom-color: rgb(94, 94, 94);
}

.game-providers a {
  position: relative;
  display: inline-block;
  background: #af0000;
  padding: 0.6rem 0;
  color: #fff;
  width: 100%;
  text-align: center;
}

.close-drawer {
  font-size: 1rem;
  color: #fff;
  padding: 0 !important;
}

/* Position the content inside the overlay */
.overlay-content {
  position: relative;
  top: 25%; /* 25% from the top */
  width: 100%; /* 100% width */
  text-align: center; /* Centered text/links */
  margin-top: 30px; /* 30px top margin to avoid conflict with the close button on smaller screens */
}

/* The navigation links inside the overlay */
.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block; /* Display block instead of inline */
  transition: 0.3s; /* Transition effects on hover (color) */
}

/* When you mouse over the navigation links, change their color */
.overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
}

/* Position the close button (top right corner) */
.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

/* When the height of the screen is less than 450 pixels, change the font-size of the links and position the close button again, so they don't overlap */
@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}
</style>